var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        width: 1000,
        title: _vm.title,
        visible: _vm.innerVisible,
        cancelText: "关闭",
        okButtonProps: { style: { display: "none" } },
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-alert",
        {
          staticStyle: { "margin-bottom": "16px" },
          attrs: { type: "info", showIcon: "" },
        },
        [
          _c(
            "template",
            { slot: "message" },
            [
              _c("span", [_vm._v("已选择")]),
              _c(
                "a",
                { staticStyle: { "font-weight": "600", padding: "0 4px" } },
                [_vm._v(_vm._s(_vm.selectedRowKeys.length))]
              ),
              _c("span", [_vm._v("项")]),
              _vm.selectedRowKeys.length > 0
                ? [
                    _c("a-divider", { attrs: { type: "vertical" } }),
                    _c("a", { on: { click: _vm.handleClearSelection } }, [
                      _vm._v("清空选择"),
                    ]),
                    _c("a-divider", { attrs: { type: "vertical" } }),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "production:task:recyclebin:button:restore",
                            expression:
                              "'production:task:recyclebin:button:restore'",
                          },
                        ],
                        on: { click: _vm.handleRevertBatch },
                      },
                      [_vm._v("批量还原")]
                    ),
                    _c("a-divider", { attrs: { type: "vertical" } }),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "production:task:recyclebin:button:delete",
                            expression:
                              "'production:task:recyclebin:button:delete'",
                          },
                        ],
                        on: { click: _vm.handleDeleteBatch },
                      },
                      [_vm._v("批量删除")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
      _c("a-table", {
        ref: "table",
        attrs: {
          rowKey: "id",
          size: "middle",
          columns: _vm.columns,
          scroll: { x: 800, y: 500 },
          loading: _vm.loading,
          dataSource: _vm.msgTableData,
          pagination: false,
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            onSelect: _vm.onSelectRow,
            onSelectAll: _vm.onSelectAll,
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "user",
              fn: function (text, record) {
                return [
                  record.user
                    ? _c("div", { staticClass: "flex flex-wrap" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(record.user ? record.user.userName : "") +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "chapterOrder",
              fn: function (text, record) {
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(record.children ? record.chapterOrder : "") +
                      "\n    "
                  ),
                ]
              },
            },
            {
              key: "department",
              fn: function (text, record) {
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        record.department ? record.department.departName : "无"
                      ) +
                      "\n    "
                  ),
                ]
              },
            },
            {
              key: "action",
              fn: function (text, record) {
                return !record.children
                  ? _c(
                      "span",
                      {},
                      [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value:
                                  "production:task:recyclebin:button:restore",
                                expression:
                                  "'production:task:recyclebin:button:restore'",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleRevert([record.id])
                              },
                            },
                          },
                          [_vm._v(" 恢复任务")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value:
                                  "production:task:recyclebin:button:delete",
                                expression:
                                  "'production:task:recyclebin:button:delete'",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete([record.id])
                              },
                            },
                          },
                          [_vm._v(" 彻底删除")]
                        ),
                      ],
                      1
                    )
                  : _vm._e()
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }