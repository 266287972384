<template>
  <a-modal
    :width="1000"
    :title="title"
    :visible="innerVisible"
    @cancel="handleCancel"
    cancelText="关闭"
    :okButtonProps="{ style: { display: 'none' } }"
  >
    <a-alert type="info" showIcon style="margin-bottom: 16px">
      <template slot="message">
        <span>已选择</span>
        <a style="font-weight: 600; padding: 0 4px">{{ selectedRowKeys.length }}</a>
        <span>项</span>
        <template v-if="selectedRowKeys.length > 0">
          <a-divider type="vertical" />
          <a @click="handleClearSelection">清空选择</a>
          <a-divider type="vertical" />
          <a @click="handleRevertBatch" v-has="'production:task:recyclebin:button:restore'">批量还原</a>
          <a-divider type="vertical" />
          <a @click="handleDeleteBatch" v-has="'production:task:recyclebin:button:delete'">批量删除</a>
        </template>
      </template>
    </a-alert>

    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :scroll="{ x: 800, y: 500 }"
      :loading="loading"
      :dataSource="msgTableData"
      :pagination="false"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        onSelect: onSelectRow,
        onSelectAll: onSelectAll
      }"
    >
      <template slot="user" slot-scope="text, record">
        <div class="flex flex-wrap" v-if="record.user">
          {{ record.user ? record.user.userName : '' }}
        </div>
      </template>

      <template slot="chapterOrder" slot-scope="text, record">
        {{ record.children ? record.chapterOrder : '' }}
      </template>
      <template slot="department" slot-scope="text, record">
        {{ record.department ? record.department.departName : '无' }}
      </template>

      <span slot="action" slot-scope="text, record" v-if="!record.children">
        <a @click="handleRevert([record.id])" v-has="'production:task:recyclebin:button:restore'"> 恢复任务</a>
        <a-divider type="vertical" />
        <a @click="handleDelete([record.id])" v-has="'production:task:recyclebin:button:delete'"> 彻底删除</a>
      </span>
    </a-table>
  </a-modal>
</template>

<script>
import { putAction, deleteAction, getFileAccessHttpUrl } from '@/api/manage'

// 高度封装的请求，请务必使用 superRequest.call(this,{}) 的方式调用
function superRequest(options) {
  this.loading = !!options.loading
  options.promise
    .then(res => {
      if (res.success && typeof options.success === 'function') {
        options.success(res)
      } else {
        throw new Error(res.message)
      }
    })
    .catch(e => {
      console.error('查询已删除的任务失败：', e)
      this.$message.warning('查询已删除的任务失败：' + (e.message || e))
    })
    .finally(() => {
      this.loading = false
    })
}

export default {
  name: 'TasksRecycleBinModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    productionId: {
      type: String | Number,
      default: '0'
    }
  },
  data() {
    return {
      title: '任务回收站',
      loading: false,
      innerVisible: false,
      selectedRowKeys: [],
      selectedRows:[],
      dataSource: [],
      columns: [
        { title: '章节序号', dataIndex: 'chapterOrder', scopedSlots: { customRender: 'chapterOrder' } },
        { title: '任务名称', dataIndex: 'taskName' },
        { title: '人员', dataIndex: 'user', scopedSlots: { customRender: 'user' } },
        { title: '操作', dataIndex: 'action', width: 200, scopedSlots: { customRender: 'action' } }
      ],
      msgTableData: [],
      url: {
        // 回收站操作，get = 获取列表；put = 取回；delete = 彻底删除
        recycleBin: '/production/tasks/recycleBin?id=' + this.productionId,
        putRecycleBin: '/production/tasks/putRecycleBin',
        deleteRecycleBin: '/production/tasks/deleteRecycleBin'
      }
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadData()
        }
        this.innerVisible = val
      }
    },
    innerVisible(val) {
      this.$emit('update:visible', val)
    }
  },
  methods: {
    loadData() {
      this.msgTableData= []
      superRequest.call(this, {
        loading: true,
        promise: this.$http.get(this.url.recycleBin),
        success: res => {
          res.data.forEach(item => {
            if (this.msgTableData.every(i => i.chapterId != item.chapterId)) {
              this.msgTableData.push({
                chapterId: item.chapterId,
                id: item.chapterId + '-one',
                chapterOrder: item.chapterOrder,
                children: [],
                readFlag: 'hasChild',
                isOpen: false
              })
            }
          })
          res.data.forEach(item => {
            this.msgTableData.forEach(i => {
              if (i.chapterId == item.chapterId) {
                i.children.push(item)
              }
            })
          })
        }
      })
    },
    handleOk() {
      this.loadData()
      this.$emit('ok')
    },
    handleCancel() {
      this.innerVisible = false
    },
    // 还原作品
    handleRevert(taskIds) {
      this.$confirm({
        title: '恢复任务',
        content: `您确定要恢复这 ${taskIds.length} 个任务吗？`,
        centered: true,
        onOk: () => {
          putAction(this.url.putRecycleBin, { taskIds: taskIds.join(',') }).then(res => {
            if (res.success) {
              this.handleOk()
              this.handleClearSelection()
              this.$message.success(`还原 ${taskIds.length} 个任务成功！`)
            }
          })
        }
      })
    },
    // 彻底删除平台
    handleDelete(taskIds) {
      this.$confirm({
        title: '彻底删除任务',
        content: (
          <div>
            <p>您确定要彻底删除这 {taskIds.length} 个任务吗？</p>
            <p style="color:red;">注意：彻底删除后将无法恢复，请谨慎操作！</p>
          </div>
        ),
        centered: true,
        onOk: () => {
          var that = this
          deleteAction(that.url.deleteRecycleBin, { taskIds: taskIds.join(',') }).then(res => {
            if (res.success) {
              this.loadData()
              this.handleClearSelection()
              this.$message.success(`彻底删除 ${taskIds.length} 个任务成功！`)
            } else {
              that.$message.warning(res.message)
            }
          })
        }
      })
    },
    handleRevertBatch() {
      this.handleRevert(this.selectedRows.filter(item=>!item.readFlag).map(i => i.id))
    },
    handleDeleteBatch() {
      console.log(this.selectedRows)
      this.handleDelete(this.selectedRows.filter(item=>!item.readFlag).map(i => i.id))
    },
    handleClearSelection() {
      this.handleTableSelectChange([], [])
    },
    handleTableSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectionRows.map(i => i.id)
      this.selectionRows = selectionRows
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows)
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    // 表格checkAll 操作
    onSelectAll(selected, selectedRows, changeRows) {
      let newArr = []
      // 将自定义字段 修改为对应状态
      if (selected) {
        // 递归添加自定义字段 checkBox: true (选中), false(未选中)
        this.traverseTree(this.msgTableData, newArr, true)
      } else {
        this.traverseTree(this.msgTableData, newArr, false)
      }
      this.msgTableData = newArr
    },
    // 表格单行数据被选中事件
    onSelectRow: function(record, selected, selectedRows) {
      record.checkFlag = !record.checkFlag // 更改选中row的选中状态
      // antD此版本 注：暂不支持父子数据递归关联选择。选中第一级时需要手动添一级下的所有第二级
      // 选中父级&&父级有子元素
      if (record.readFlag && record.children.length > 0) {
        // 修改子级的自定义checkFlag值
        record.children.forEach(item => {
          item.checkFlag = record.checkFlag
        })
        // 判断是选中还是取消选中
        if (record.checkFlag) {
          // 选中
          // 手动添加选中店第二级
          this.selectedRowKeys.push(record.id) // 一级id存入到选中的key集合中
          this.selectedRows.push(record) // 一级id存入到选中的row集合中
          // 遍历选中的一级的子级 子级全部存入到选中的key集合中、row集合中
          record.children.map(account => {
            this.selectedRowKeys.push(account.id)
            // 去重- 关键
            this.selectedRowKeys = this.selectedRowKeys.filter((x, index, self) => self.indexOf(x) === index)
          })
          this.selectedRows = this.selectedRows.concat(record.children)
          // 去重- 关键
          this.selectedRows = this.selectedRows.filter((x, index, self) => self.indexOf(x) === index)
        } else {
          // 取消选中
          // 取消本身
          this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== record.id)
          this.selectedRows = this.selectedRows.filter(item => item.id !== record.id)

          // 手动取消一级下所有二级的选中状态
          record.children.forEach(account => {
            this.selectedRowKeys.forEach((selectKey, index) => {
              if (account.id === selectKey) {
                this.selectedRowKeys.splice(index, 1)
              }
            })
            this.selectedRows.forEach((selectRow, index) => {
              if (account.id === selectRow.id) {
                this.selectedRows.splice(index, 1)
              }
            })
          })
        }
      } else {
        // 操作子级checkbox
        // 找到子级所属父级
        let parent = ''
        this.msgTableData.forEach(item => {
          if (item.readFlag&&item.chapterId == record.chapterId) {
            parent = item
          }
        })
        if (record.checkFlag) {
          // 选中
          // 选中子级 如果子级全部被选中，对应父级被选中
          let checkArr = []
          checkArr = parent.children.filter(account => account.checkFlag)
          if (checkArr.length === parent.children.length) {
            // 子级全部被选中
            // 父级变更为被选中
            this.msgTableData.forEach(item => {
              if (item.id === record.childID) {
                item.checkFlag = true
              }
            })
            this.selectedRowKeys.push(parent.id)
            this.selectedRows.push(parent)
          }
        } else {
          // 取消任意子级选中状态 父级取消被选中
          this.selectedRowKeys.forEach((selectKey, index) => {
            if (parent.id === selectKey) {
              this.selectedRowKeys.splice(index, 1)
            }
          })
          this.selectedRows.forEach((selectRow, index) => {
            if (parent.id === selectRow.id) {
              this.selectedRows.splice(index, 1)
            }
          })
          // 父级变更为取消选中
          this.msgTableData.forEach(item => {
            if (item.id === record.childID) {
              item.checkFlag = false
            }
          })
        }
      }
    },
    /**
     * 递归给数据添加自定字段 checkFlag：true 选中 false 未选中 初始状态：false
     * @param list 递归list
     * @param arr
     * @param booleanFlag
     * */
    traverseTree(list, arr, booleanFlag) {
      list.forEach(ele => {
        this.$set(ele, 'checkFlag', booleanFlag)
        if (ele.children && ele.children.length > 0) {
          let t = [] // 重要定义一个空array
          this.traverseTree(ele.children, t, booleanFlag)
        }
        arr.push(ele)
        // 清洗数据 将市场 ltlHandlingTime = -1的数据设置为空
        arr.forEach(item => {
          if (item.ltlHandlingTime === -1) {
            item.ltlHandlingTime = ''
          }
        })
        return arr
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-table-selection-column {
  > span {
    display: flex !important;
    justify-content: center !important;
  }
}
</style>
